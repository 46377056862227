import { Base, createApp, importWhenVisible } from '@studiometa/js-toolkit';
import { PrefetchWhenVisible, AnchorScrollTo } from '@studiometa/ui';
import Overlay from './atoms/Overlay.js';

/**
 * App class.
 */
class App extends Base {
  /**
   * Class config.
   * @type {import('@studiometa/js-toolkit').BaseConfig}
   */
  static config = {
    name: 'App',
    components: {
      Overlay,
      'a[href^="#"]': AnchorScrollTo,
      Reveal: () => import('./atoms/Reveal.js'),
      Accordion: () => import('./molecules/Accordion.js'),
      FadeGroup: () => import('./molecules/FadeGroup/index.js'),
      Figure: () => import('./atoms/Figure.js'),
      HoverCrossNav: () => import('./molecules/HoverCrossNav.js'),
      Cursor: () => import('./atoms/Cursor.js'),
      Main: () => import('./molecules/Main.js'),
      Header: () => import('./molecules/Header.js'),
      '.magnetic-btn': () => import('./atoms/MagneticBtn.js'),
      Menu: () => import('./atoms/Menu.js'),
      ScrollIndicator: () => import('./atoms/ScrollIndicator.js'),
      ThemeSwitcher: () => import('./atoms/ThemeSwitcher.js'),
      Sticky: () => import('./molecules/Sticky.js'),
      Slider: () => import('./molecules/Slider.js'),
      Textarea: (app) => importWhenVisible(() => import('./atoms/Textarea.js'), 'Textarea', app),
      'a[href]': PrefetchWhenVisible,
    },
  };

  /**
   * Display loader on internal link click.
   * @param   {MouseEvent} event
   * @returns {Promise<void>}
   */
  async onClick(event) {
    if (
      event.target instanceof HTMLAnchorElement ||
      (event.target instanceof Element &&
        event.target.matches('a[href], a[href] *, :not(a[href^="#"])'))
    ) {
      const closestAnchor =
        event.target instanceof HTMLAnchorElement ? event.target : event.target.closest('a');

      if (
        !closestAnchor ||
        (closestAnchor.pathname === window.location.pathname && closestAnchor.hash !== '')
      ) {
        return;
      }

      if (closestAnchor.origin === window.location.origin) {
        event.preventDefault();
        await Promise.all(this.$children.Overlay.map((overlay) => overlay.leave()));
        setTimeout(() => {
          window.location.href = closestAnchor.href;
        }, 0);
      }
    }
  }

  /**
   * Get the current active theme
   * @returns {string} The name of the current active theme
   */
  get activeTheme() {
    return this.$el.dataset.activeTheme;
  }

  /**
   * Set the active theme
   * @param  {string} theme The theme to set
   * @returns {void}
   */
  set activeTheme(theme) {
    this.$el.dataset.activeTheme = theme;
  }

  /**
   * Change theme
   * @param {string} theme
   */
  onThemeSwitcherChange(theme) {
    this.activeTheme = theme;
    document.body.dataset.activeTheme = theme;
  }
}

export default createApp(App);
