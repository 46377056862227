import { Transition } from '@studiometa/ui';

/**
 * Overlay class.
 */
export default class Overlay extends Transition {
  /**
   * Config.
   */
  static config = {
    name: 'Overlay',
  };

  /**
   * mounted
   */
  mounted() {
    this.enter();
  }

  /**
   * destroyed
   */
  destroyed() {
    this.leave();
  }
}
